import React from 'react';
import './Index.css';


function Footer() {
    return (
        <footer className='footer'>
            <p className='footer-text'>Copyright 2020 Argent Bank</p>
        </footer>
    )
}

export default Footer;
