import React from 'react';
import './Index.css';
import chat from '../../img/icon-chat.png'
import money from '../../img/icon-money.png'
import security from '../../img/icon-security.png'

function Features() {
  return (
    <div className='features'>
        <h2 className='sr-only'>Features</h2>

        <div className='features-item'>
            <img src={chat} alt="Chat" className='features-icon' />
            <h3 className='features-item-title'>You are our #1 priority</h3>
            <p>Need to talk to a representative? You can get in touch through our
            24/7 chat or through a phone call in less than 5 minutes.</p>
        </div>

        <div className='features-item'>
            <img src={money} alt="Money" className='features-icon' />
            <h3 className='features-item-title'>More savings means higher rates</h3>
            <p>The more you save with us, the higher your interest rate will be!</p>
        </div>

        <div className='features-item'>
            <img src={security} alt="Security" className='features-icon' />
            <h3 className='features-item-title'>Security you can trust</h3>
            <p>We use top of the line encryption to make sure your data and money
            is always safe.</p>
        </div>

    </div>
  )
}

export default Features;
